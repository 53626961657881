import vcookingGif from './assets/vcooking.gif';

function App() {
  return (
    <div className="container">
      <img className="cookingGif" src={vcookingGif} alt="cooking gif" />

      <h2 className="mainText">
        Cooking up something special...
      </h2>
    </div>
  );
}

export default App;
